<template>

    <div class="w-full h-full px-3 py-4">
        
        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">
            <All model="Groupdimensions" 
            :immediate="true" 
            :data.sync="dimensions"
            v-slot="{ data, loading}">
                <div v-if="!loading" class="h-auto w-full px-2 mt-6 flex flex-col justify-center items-start">
                    <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('indicator')}}</span>
                    <div class="h-8 w-full">
                        <div class="h-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                            <!-- <el-select v-model="dimensionSelected" :placeholder="$t('select')" class="w-full">
                                <el-option
                                v-for="item in data"
                                :key="item.Key"
                                :label="translate(item.Name)"
                                :value="item.Key"
                                class="w-full">
                                </el-option>
                            </el-select> -->
                            <select name="dimensionSelected" v-model="dimensionSelected" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                <option v-for="(el,index) in data" :key="index" :value="el.Key" :class="'text-dfont-'+mode">{{translate(el.Name)}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </All>
            <div class="h-auto w-full px-2 mt-6 flex flex-col justify-center items-start">
                <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('period')}}</span>
                <div class="h-8 w-full">
                    <div class="h-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                        <!-- <el-select v-model="period" :placeholder="$t('select')" class="w-full">
                            <el-option
                            v-for="item in periods"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                            class="w-full">
                            </el-option>
                        </el-select> -->
                        <select name="period" v-model="period" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                            <option v-for="(el,index) in periods" :key="index" :value="el.value" :class="'text-dfont-'+mode">{{el.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <filter-months v-if="period == 'month'"   :mode='mode'/>
            <div class="h-10 mt-12 px-12">
                <div class="h-full rounded-lg bg-red flex flex-row justify-center items-center" @click="applyfilters()">
                    <span class="text-lg text-white">{{$t('apply')}}</span>
                </div>
            </div>
            <div class="h-6"></div>
        </div>
    </div>

</template>

<script>
import { All } from '@/api/components';
import { state, actions } from '@/store';

export default {
    components:{
        All
    },
    data(){
        return{
            options: ['uno', 'dos', 'tres', 'cuatro', 'cinco'],
            periods: [
                { 
                    name: this.$t("monthly"),
                    value: 'month'
                },
                { 
                    name: this.$t("quarterly"),
                    value: 'quarter'
                },
                { 
                    name: this.$t("annual"),
                    value: 'year'
                }
            ],
            companies: [
                { 
                    name: state.companySelected,
                    value: false
                },
                { 
                    name: 'PAMESA GRUPO INDUSTRIAL',
                    value: true
                }
            ],
            period: '',
            dimensionSelected:{
                Key: null,
                Name: null
            },
            companyMode: null,
            dimensions: [],
            dimensionOption: null
        }
    },
    methods: {
        translate(word) {
            switch (word) {
                case 'Facturación':
                    return this.$t("billing")
                    break;
                case 'Metros cuadrados':
                    return this.$t("squareMeters")
                    break;
                case 'Precio medio':
                    return this.$t("averagePrice")
                    break;
                case 'Margen':
                    return this.$t("margin")
                    break;
            }
        },
        applyfilters() {
            actions.setPeriod(this.period)
            actions.setDimensionGroup(this.dimensionSelected),
            actions.setDimensionName(this.dimensions[this.dimensionSelected].Name)
            this.$router.go(-1)
        }
    },
    computed: {
        companyselected() {
            return state.companySelected
        },
        mode(){
            return state.mode
        }
    },
    mounted() {
        this.dimensionSelected = state.dimensionGroup
        this.period = state.period
        this.companyMode = false
    }
}
</script>

<style>
    /* .el-input__inner {
        background-color: rgba(0,0,0,0) !important;
        border: 0;
        color: #2C5358;
    } */
</style>